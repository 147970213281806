import { Route, Routes } from 'react-router-dom'
//
import DashBoardPage from './pages/dashboard/dashboard'
import PrivacyPage from './pages/docs/privacy'
import TermPage from './pages/docs/term'
import FAQPage from './pages/faq'
import HomePage from './pages/home'
import NoticeDetailPage from './pages/notice/detail'
import NoticePage from './pages/notice/page'
import PostListPage from './pages/post'
import AddProductPage from './pages/product/addProduct'
import BulkProductPage from './pages/product/bulkProduct'
import ProductListPage from './pages/product/productList'
import RegisterAccountInfo from './pages/registerAccountInfo'
import RegisterBizInfo from './pages/registerBizInfo'
import RegisterComplete from './pages/registerComplete'
import RegisterPolicy from './pages/registerPolicy'
import RegisterUserInfo from './pages/registerUserInfo'
import SellListPage from './pages/sell/sellList'
import AddSnapPage from './pages/snap/addSnap'
import EditSnapPage from './pages/snap/editSnap'
import StylistAddModelPage from './pages/stylist/addModel'
import StylistBasicInfoPage from './pages/stylist/basicInfo'
import StylistModelInfoPage from './pages/stylist/modelInfo'
import TransactionPage from './pages/transaction/transactionList'
import ProductStockListPage from './pages/sell/productStockList'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

//

const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/docs/privacy" element={<PrivacyPage />} />
        <Route path="/docs/term" element={<TermPage />} />
        <Route path="/dashboard" element={<DashBoardPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/register/policy" element={<RegisterPolicy />} />
        <Route path="/register/biz" element={<RegisterBizInfo />} />
        <Route path="/register/account" element={<RegisterAccountInfo />} />
        <Route path="/register/user" element={<RegisterUserInfo />} />
        <Route path="/register/complete" element={<RegisterComplete />} />
        <Route path="/stylist/info" element={<StylistBasicInfoPage />} />
        <Route path="/stylist/model" element={<StylistModelInfoPage />} />
        <Route path="/stylist/model/add" element={<StylistAddModelPage />} />
        <Route path="/snap" element={<PostListPage />} />
        <Route path="/snap/add" element={<AddSnapPage />} />
        <Route path="/snap/add/:id" element={<EditSnapPage />} />
        <Route path="/product" element={<ProductListPage />} />
        <Route path="/sell" element={<SellListPage />} />
        <Route path="/sell/stock" element={<ProductStockListPage />} />
        <Route path="/transaction" element={<TransactionPage />} />
        <Route path="/product/add" element={<AddProductPage />} />
        <Route path="/product/bulk" element={<BulkProductPage />} />
        <Route path="/notice" element={<NoticePage />} />
        <Route path="/notice/:id" element={<NoticeDetailPage />} />
      </Routes>
    </DndProvider>
  )
}

export default App
